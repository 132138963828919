<template>

  <el-scrollbar :max-height="200">
    <div :style="`
            display: flex;
            align-items: center;
            line-height: ${height}px;
            background-color: ${background};
            width: ${width};
            color: ${color};
            margin-bottom:4px;
            border-radius: 4px;`"
         v-for="(attachment,attachment_index) in value"
         :key="attachment_index">
      <div style="flex: 1;">
        <el-tooltip effect="dark" placement="top-end" :show-after="600">
          <template #content>
            {{ attachment.file_name }}
          </template>
          <span style="margin-left: 12px">{{ ellipsis(attachment.file_name) }}</span>
        </el-tooltip>&nbsp;
        <span style="font-size: 12px;margin-left: 4px">({{ attachment.size }})</span>
      </div>
      <div style="margin:0 4px">
        <el-tooltip effect="dark" placement="top-start" :show-after="1000"
                    v-if="attachmentStore.isCanReadFileType(attachment.type)">
          <template #content>预览</template>
          <el-link type="info" icon="View" @click="preview(attachment.file_name,attachment.id)" :underline="false"></el-link>
        </el-tooltip>
        <el-tooltip effect="dark" placement="top-start" :show-after="1000">
          <template #content>下载</template>
          <el-link type="info" icon="Download" @click="download(attachment.file_name,attachment.id)" :underline="false"
                   style="margin-left: 15px"></el-link>
        </el-tooltip>
      </div>
    </div>
  </el-scrollbar>


  <attachment-viewer ref="attachmentViewer"></attachment-viewer>
</template>

<script>
import attachmentViewer from "@/components/common/attachmentViewer.vue";
import {mapStores} from "pinia";
import {useAttachmentStore} from "@/stores/attachment";

export default {
  components: {
    attachmentViewer
  },
  props: {
    height: {
      type: Number,
      default: 31
    },
    width: {
      type: String,
      default: '100%'
    },
    background: {
      type: String,
      default: '#F8FAFC'
    },
    color: {
      type: String,
      default: '#344262'
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapStores(useAttachmentStore)
  },
  methods: {
    download(fileName, id) {
      this.attachmentStore.download(id, fileName)
    },
    preview(title, id) {
      this.$refs.attachmentViewer.call(id, title)
    },
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 15) {
        return value.slice(0, 10) + "..." + value.slice(value.length - 6, value.length);
      }
      return value;
    },
  },
}
</script>

<style scoped lang="scss">
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
