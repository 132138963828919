<template>


  <div>
    <group-container v-if="type==='fill'"
                     :style="{margin:context.parent?'0':'40px 0',padding:context.parent?'0':'0 0 0 20px'}">
      <template #title>
        <div style="display: flex;align-items: center">
          {{ element.label }}
          <el-tooltip v-if="element.describe" effect="dark" :content="element.describe" placement="right-start">
            <template #content>
              <div v-html="element.describe.replace(/\n/g, '<br>')"></div>
            </template>
            <el-icon style="margin-left: 4px">
              <InfoFilled/>
            </el-icon>
          </el-tooltip>
        </div>
      </template>
      <div style="margin-bottom: 20px">
        <el-button v-prevent-re-click type="primary" icon="Plus" @click="addRow()"
                   style="height: 26px;font-weight: normal;">新增
        </el-button>
        <el-button v-prevent-re-click icon="Download" @click="$refs.excelImport.call()"
                   style="height: 26px;font-weight: normal;">导入
        </el-button>
        <el-button v-prevent-re-click style="height: 26px;font-weight: normal;" type="danger"
                   @click="deleteMultipleSelection">删除
        </el-button>
      </div>
      <el-table ref="table" :key="tableKey" :data="this.tableData" style="width:100%;border-radius: 6px;"
                border :default-sort="{ prop: 'index', order: 'descending' }"
                :scrollbar-always-on="scrollbar_always_on"
                :header-cell-style="{color:'#181A1D'}" @selection-change="handleSelectionChange"
                :max-height="element.attrs.height||300" size="small">
        <el-table-column type="selection" width="50" align="center"/>
        <el-table-column label="序号" type="index" sortable width="60px" align="center"></el-table-column>
        <template v-for="(child,index) in element.children" :key="index">
          <el-table-column v-if="isHide(child, data)" :label="child.label" :min-width="getMinWidth(child)">
            <template #header>
              <span :style="{ color:'#344262','font-weight':context.form.setting.style.PC.title}">
                {{child.label}}
                <form-element-tooltip :element="child"></form-element-tooltip>
              </span>
            </template>
            <template #default="scope">
              <component :is="`design${child.tag.charAt(0).toUpperCase() + child.tag.slice(1)}Preview`"
                         :context="{
                      form:context.form,
                      parent:element,
                      data:scope.row,
                      rawData:context.data,
                      authentication:context.authentication,
                      type:type,
                      }"
                         :element="child">
              </component>
            </template>
          </el-table-column>
          <el-table-column v-else label="" :width="1">
            <template #default="scope">
              <component v-show="false" :is="`design${child.tag.charAt(0).toUpperCase() + child.tag.slice(1)}Preview`"
                         :context="{
                      form:context.form,
                      parent:element,
                      data:child.tag==='ocr'?context.data: scope.row,
                      rawData:context.data,
                      authentication:context.authentication,
                      type:type,
                      }"
                         :element="child">
              </component>
            </template>
          </el-table-column>

        </template>
        <el-table-column label="操作" width="80px" align="center" fixed="right">
          <template #default="scope">
            <el-dropdown trigger="click">
              <el-icon color="#606266">
                <MoreFilled/>
              </el-icon>

              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="copy(scope.$index)">复制</el-dropdown-item>
                  <el-dropdown-item @click="clear(scope.$index)">清空行数据</el-dropdown-item>
                  <el-dropdown-item @click="addLast(scope.$index)">在上面添加行</el-dropdown-item>
                  <el-dropdown-item @click="addBottom(scope.$index)">在下面添加行</el-dropdown-item>
                  <el-dropdown-item @click="move(scope.$index,-1)" divided>向上移动</el-dropdown-item>
                  <el-dropdown-item @click="move(scope.$index,1)">向下移动</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </group-container>
    <group-container v-else-if="type==='detail'"
                     :style="{margin:context.parent?'0':'40px 0',padding:context.parent?'0':'0 0 0 20px'}">
      <template #title>
        <div style="display: flex;align-items: center">
          {{ element.label }}
          <form-element-tooltip :element="element"></form-element-tooltip>
        </div>
      </template>
      <el-table ref="table" :key="tableKey" :data="tableData" style="width:100%;border-radius: 6px;"
                border
                :scrollbar-always-on="scrollbar_always_on"
                :header-cell-style="{color:'#181A1D'}" @selection-change="handleSelectionChange"
                :max-height="element.attrs.height||300">
        <el-table-column label="序号" type="index" width="60px" fixed align="center" sortable></el-table-column>
        <template v-for="(child,index) in element.children" :key="index">
          <el-table-column v-if="isHide(child, data)" :label="child.label" :min-width="getMinWidth(child)">
            <template #header>
              <span :style="{ color:'#344262','font-weight':context.form.setting.style.PC.title}">
                {{child.label}}
                <form-element-tooltip :element="child"></form-element-tooltip>
              </span>
            </template>
            <template #default="scope">
              <component :is="`design${child.tag.charAt(0).toUpperCase() + child.tag.slice(1)}Preview`" :element="child"
                         :context="{
                      form:context.form,
                      parent:element,
                      data:scope.row,
                      rawData:context.data,
                      authentication:context.authentication,
                      type:type}">
              </component>

            </template>
          </el-table-column>
          <el-table-column v-else :min-width="-1">
            <template #default="scope">
              <component v-show="false" :is="`design${child.tag.charAt(0).toUpperCase() + child.tag.slice(1)}Preview`"
                         :element="child"
                         :context="{
                      form:context.form,
                      parent:element,
                      data:scope.row,
                      rawData:context.data,
                      authentication:context.authentication,
                      type:type}">
              </component>

            </template>
          </el-table-column>
        </template>

      </el-table>
    </group-container>
    <excel-import v-if="type==='fill' && context.form"
                  ref="excelImport" @callback="callbackImport" :form-id="context.form.id"
                  :subForm="element.id"></excel-import>
  </div>

</template>

<script>

import {mapStores} from "pinia";
import {merge} from 'lodash'
import {useFlowStore} from "@/stores/flow";
import GroupContainer from "@/components/common/groupContainer.vue";
import FormElementTooltip from "@/components/common/formElementTooltip.vue";
import ExcelImport from "@/components/import/excel/index.vue";

export default {
  components: {ExcelImport, FormElementTooltip, GroupContainer},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      data: {},
      scrollbar_always_on: false,
      type: '',
      multipleSelection: [],
      tableData: [],
      tableKey: Math.random(),
      merge: merge
    }
  },
  computed: {
    ...mapStores(useFlowStore)
  },
  watch: {
    'tableData.length': {
      handler(newValue, oldValue) {
        // oldValue为0是表格还未初始化，这个时候不能刷新
        if (oldValue !== 0) {
          this.tableData.forEach(row => {
            this.element.children.forEach((item, index) => {
              if (row[item.id] === undefined) {
                row[item.id] = this.deepClone(item.value);
              }
            })
          })

          this.tableKey = Math.random()
          this.scrollToBottom();

        }
      },
      //如果deep为false，同样length但是数据不同的情况下，不会触发刷新
      deep: true,
      immediate: false
    }
  },
  created() {
    this.data = this.context.data;
    this.type = this.flowStore.typeOfElement(this.element, this.context);
    if (this.element.attrs === undefined) {
      this.scrollbar_always_on = false;
    } else {
      this.scrollbar_always_on = !!this.element.attrs.scrollbar_always_on;
    }


    if (!this.data[this.element.id]) {
      this.data[this.element.id] = []
    }


    if (this.data[this.element.id].length === 0 && this.type === 'fill') {
      this.tableData = this.data[this.element.id];
      let initialRows = this.element.attrs.initialRows ? this.element.attrs.initialRows : 1;
      for (let i = 0; i < initialRows; i++) {
        this.addRow()
      }

    }


    this.tableData = this.data[this.element.id];
  },
  methods: {
    addRow(row, index) {

      if (!row) {
        row = {};
        this.element.children.forEach((item, index) => {
          row[item.id] = this.deepClone(item.value);
        })
      }

      row.id = this.randomId();

      if (index === undefined) {
        this.tableData.push(row);
      } else {
        this.tableData.splice(index, 0, row);
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    deleteMultipleSelection() {
      if (this.multipleSelection.length > 0) {
        this.multipleSelection.forEach(item => {
          this.tableData.splice(this.tableData.indexOf(item), 1);
        })

        this.multipleSelection = [];
      }

    },
    copy(index) {
      this.addRow(this.deepClone(this.tableData[index]))
    },
    clear(index) {
      let row = this.tableData[index]
      this.element.children.forEach((item, index) => {
        row[item.id] = item.value;
      })
    },
    addLast(index) {
      this.addRow(null, index)
    },
    addBottom(index) {
      this.addRow(null, index + 1)
    },
    move(index, offset) {

        if(this.tableData[index + offset]){
          let element = this.tableData[index]
          this.tableData[index] = this.tableData[index + offset];
          this.tableData[index + offset] = element;
          this.tableKey = Math.random()
        }

    },
    getMinWidth(element) {
      if (element.tag === 'switch') {
        return 100;
      } else if (element.tag === 'number') {
        return 100;
      } else if (element.tag === 'referenceForm') {
        return 170;
      } else if (element.tag === 'textarea') {
        return 220;
      } else if(element.tag === 'attachment'){
        return  320;
      }
      return 200;
    },
    isHide(element, data) {
      return this.context.authentication.isHide(element, data)
    },
    callbackImport(list) {

      list.forEach(row => {
        this.addRow(row)
      })

    }, scrollToBottom() {
      if (this.type === 'fill') {
        this.$nextTick(() => {
          let table = this.$refs.table
          let timer_i = 0;
          let timer = setInterval(() => {
            let $bodyWrapper = table.$el.querySelector(".el-table__body");
            table.setScrollTop($bodyWrapper.scrollHeight)

            timer_i += 1;
            if (timer_i >= 5) {
              clearInterval(timer)
            }
          }, 200)
        })
      }
    }
  }
}

</script>

<style scoped lang="scss">
.el-button {
  height: 28px;
  font-size: 14px;
  font-weight: normal;
}

</style>