import './plugins/axios'
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import commonJs from "@/assets/common";
import animated from 'animate.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "@/assets/styles/index.scss";
import {createPinia} from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import CKEditor from '@ckeditor/ckeditor5-vue';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {ElMessage, ElMessageBox, ElNotification} from 'element-plus'
import '@imengyu/vue3-context-menu/lib/vue3-context-menu.css'
import ContextMenu from '@imengyu/vue3-context-menu'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import columnShow from "@/components/design-form-list/columnShow.vue";
import {VueSignaturePad} from 'vue-signature-pad';
import vueEsign from 'vue-esign'


import designAddress from "@/components/design-form/items/designAddress.vue";
import designAttachment from "@/components/design-form/items/designAttachment.vue";
import designCheckbox from "@/components/design-form/items/designCheckbox.vue";
import designDate from "@/components/design-form/items/designDate.vue";
import designHbox from "@/components/design-form/items/designHbox.vue";
import designInput from "@/components/design-form/items/designInput.vue";
import designNumber from "@/components/design-form/items/designNumber.vue";
import designRadio from "@/components/design-form/items/designRadio.vue";
import designSelect from "@/components/design-form/items/designSelect.vue";
import designSwitch from "@/components/design-form/items/designSwitch.vue";
import designTextarea from "@/components/design-form/items/designTextarea.vue";
import designGroupTitle from "@/components/design-form/items/designGroupTitle.vue";
import designDescription from "@/components/design-form/items/designDescription.vue";
import designImage from "@/components/design-form/items/designImage.vue";
import designSubForm from "@/components/design-form/items/designSubForm.vue";
import designReferenceForm from "@/components/design-form/items/designReferenceForm.vue";
import designDept from "@/components/design-form/items/designDept.vue";
import designPeople from "@/components/design-form/items/designPeople.vue";
import designCoding from "@/components/design-form/items/designCoding.vue";
import designReferenceFormSelect from "@/components/design-form/items/designReferenceFormSelect.vue";
import designOcr from "@/components/design-form/items/designOcr.vue";

import designNumberPreview from "@/components/design-form/items/designNumberPreview.vue";
import designInputPreview from "@/components/design-form/items/designInputPreview.vue";
import designAddressPreview from "@/components/design-form/items/designAddressPreview.vue";
import designAttachmentPreview from "@/components/design-form/items/designAttachmentPreview.vue";
import designCheckboxPreview from "@/components/design-form/items/designCheckboxPreview.vue";
import designDatePreview from "@/components/design-form/items/designDatePreview.vue";
import designDescriptionPreview from "@/components/design-form/items/designDescriptionPreview.vue";
import designGroupTitlePreview from "@/components/design-form/items/designGroupTitlePreview.vue";
import designHboxPreview from "@/components/design-form/items/designHboxPreview.vue";
import designRadioPreview from "@/components/design-form/items/designRadioPreview.vue";
import designSelectPreview from "@/components/design-form/items/designSelectPreview.vue";
import designSwitchPreview from "@/components/design-form/items/designSwitchPreview.vue";
import designTextareaPreview from "@/components/design-form/items/designTextareaPreview.vue";
import designImagePreview from "@/components/design-form/items/designImagePreview.vue";
import designSubFormPreview from "@/components/design-form/items/designSubFormPreview.vue";
import designReferenceFormPreview from "@/components/design-form/items/designReferenceFormPreview.vue";
import designPeoplePreview from "@/components/design-form/items/designPeoplePreview.vue";
import designDeptPreview from "@/components/design-form/items/designDeptPreview.vue";
import designCodingPreview from "@/components/design-form/items/designCodingPreview.vue";
import designReferenceFormSelectPreview from "@/components/design-form/items/designReferenceFormSelectPreview.vue";
import designOcrPreview from "@/components/design-form/items/designOcrPreview.vue";

import designNumberPrint from "@/components/design-form/items/designNumberPrint.vue";
import designInputPrint from "@/components/design-form/items/designInputPrint.vue";
import designAddressPrint from "@/components/design-form/items/designAddressPrint.vue";
import designAttachmentPrint from "@/components/design-form/items/designAttachmentPrint.vue";
import designCheckboxPrint from "@/components/design-form/items/designCheckboxPrint.vue";
import designDatePrint from "@/components/design-form/items/designDatePrint.vue";
import designDescriptionPrint from "@/components/design-form/items/designDescriptionPrint.vue";
import designGroupTitlePrint from "@/components/design-form/items/designGroupTitlePrint.vue";
import designHboxPrint from "@/components/design-form/items/designHboxPrint.vue";
import designRadioPrint from "@/components/design-form/items/designRadioPrint.vue";
import designSelectPrint from "@/components/design-form/items/designSelectPrint.vue";
import designSwitchPrint from "@/components/design-form/items/designSwitchPrint.vue";
import designTextareaPrint from "@/components/design-form/items/designTextareaPrint.vue";
import designImagePrint from "@/components/design-form/items/designImagePrint.vue";
import designSubFormPrint from "@/components/design-form/items/designSubFormPrint.vue";
import designReferenceFormPrint from "@/components/design-form/items/designReferenceFormPrint.vue";
import designPeoplePrint from "@/components/design-form/items/designPeoplePrint.vue";
import designDeptPrint from "@/components/design-form/items/designDeptPrint.vue";
import designCodingPrint from "@/components/design-form/items/designCodingPrint.vue";
import designReferenceFormSelectPrint from "@/components/design-form/items/designReferenceFormSelectPrint.vue";
import designOcrPrint from "@/components/design-form/items/designOcrPrint.vue";

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

import * as echarts from 'echarts';

dayjs.locale('zh-cn');


import SimpleCondition from "@/components/design-form-list/simpleCondition.vue";
import FormDataSelect from "@/components/common/formDataSelect.vue";


let app = createApp(App);


import "vue-draggable-resizable/style.css";

app.config.globalProperties.$message = ElMessage;
app.config.globalProperties.$messageBox = ElMessageBox;
app.config.globalProperties.$notification = ElNotification;
app.config.globalProperties.$echarts = echarts;


app.component("SimpleCondition", SimpleCondition)
app.component("FormDataSelect", FormDataSelect)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.component("columnShow", columnShow)

app.component("designAddress", designAddress);
app.component("designAttachment", designAttachment);
app.component("designCheckbox", designCheckbox);
app.component("designDate", designDate);
app.component("designHbox", designHbox);
app.component("designInput", designInput);
app.component("designNumber", designNumber);
app.component("designRadio", designRadio);
app.component("designSelect", designSelect);
app.component("designSwitch", designSwitch);
app.component("designTextarea", designTextarea);
app.component("designGroupTitle", designGroupTitle);
app.component("designDescription", designDescription);
app.component("designImage", designImage);
app.component("designSubForm", designSubForm)
app.component("designReferenceForm", designReferenceForm)
app.component("designDept", designDept)
app.component("designPeople", designPeople)
app.component("designCoding", designCoding)
app.component("designReferenceFormSelect", designReferenceFormSelect)
app.component("designOcr", designOcr)


app.component("designInputPreview", designInputPreview);
app.component("designAddressPreview", designAddressPreview);
app.component("designAttachmentPreview", designAttachmentPreview);
app.component("designCheckboxPreview", designCheckboxPreview);
app.component("designDatePreview", designDatePreview);
app.component("designDescriptionPreview", designDescriptionPreview);
app.component("designGroupTitlePreview", designGroupTitlePreview);
app.component("designHboxPreview", designHboxPreview);
app.component("designNumberPreview", designNumberPreview);
app.component("designRadioPreview", designRadioPreview);
app.component("designSelectPreview", designSelectPreview);
app.component("designSwitchPreview", designSwitchPreview);
app.component("designTextareaPreview", designTextareaPreview);
app.component("designImagePreview", designImagePreview);
app.component("designSubFormPreview", designSubFormPreview);
app.component("designReferenceFormPreview", designReferenceFormPreview)
app.component("designDeptPreview", designDeptPreview);
app.component("designPeoplePreview", designPeoplePreview)
app.component("designCodingPreview", designCodingPreview)
app.component("designReferenceFormSelectPreview", designReferenceFormSelectPreview)
app.component("designOcrPreview", designOcrPreview)

app.component("designInputPrint", designInputPrint);
app.component("designAddressPrint", designAddressPrint);
app.component("designAttachmentPrint", designAttachmentPrint);
app.component("designCheckboxPrint", designCheckboxPrint);
app.component("designDatePrint", designDatePrint);
app.component("designDescriptionPrint", designDescriptionPrint);
app.component("designGroupTitlePrint", designGroupTitlePrint);
app.component("designHboxPrint", designHboxPrint);
app.component("designNumberPrint", designNumberPrint);
app.component("designRadioPrint", designRadioPrint);
app.component("designSelectPrint", designSelectPrint);
app.component("designSwitchPrint", designSwitchPrint);
app.component("designTextareaPrint", designTextareaPrint);
app.component("designImagePrint", designImagePrint);
app.component("designSubFormPrint", designSubFormPrint);
app.component("designReferenceFormPrint", designReferenceFormPrint)
app.component("designDeptPrint", designDeptPrint);
app.component("designPeoplePrint", designPeoplePrint)
app.component("designCodingPrint", designCodingPrint)
app.component("designReferenceFormSelectPrint", designReferenceFormSelectPrint)
app.component("designOcrPrint", designOcrPrint)

//ETL
import etlForm from "@/components/etl/node/form"

app.component("etlForm", etlForm)

import etlFilter from "@/components/etl/node/filter"

app.component("etlFilter", etlFilter)

import etlJoin from "@/components/etl/node/join"

app.component("etlJoin", etlJoin)

import etlFill from "@/components/etl/node/fill"

app.component("etlFill", etlFill)

import etlSquash from "@/components/etl/node/squash"

app.component("etlSquash", etlSquash)

import etlBuild from "@/components/etl/node/build"

app.component("etlBuild", etlBuild)

import etlCalc from "@/components/etl/node/calc"

app.component("etlCalc", etlCalc)


//报表
import chartTable from "@/components/design-report/chart/chartTable.vue"
app.component("chartTable", chartTable)

//电子签名
app.component("VueSignaturePad", VueSignaturePad);


let pinia = createPinia().use(piniaPersist)

/**  防抖动 */
app.directive("preventReClick", {
    mounted(el, binding) {
        el.addEventListener("click", () => {
            if (!el.disabled) {
                el.disabled = true;
                setTimeout(() => el.disabled = false, binding.value || 300);
            }
        });
    },
});

app.use(commonJs).use(vueEsign).use(pinia).use(ElementPlus, {
    locale: zhCn,
}).use(ContextMenu).use(CKEditor).use(router).mount('#app')
