import {defineStore} from 'pinia'
import ocrController from "@/controller/ocrController";
import {post} from "@/plugins/axios";
import _ from "lodash";
import {_ocrTypeColumns} from "@/pojo/data-design-form-aside";


export const useOcrStore = defineStore('ocr', {
    state: () => {
    },
    getters: {},
    actions: {
        getToken() {
            return ocrController.getToken();
        },
        table(token, base64Image) {
            return ocrController.table(token, base64Image);
        },
        identify(token, base64Image, type) {
            switch (type) {
                case '身份证':
                    return ocrController.idcard(token, base64Image);
                case '银行卡':
                    return ocrController.bankcard(token, base64Image);
                case '驾驶证':
                    return ocrController.driving_license(token, base64Image);
                case '行驶证':
                    return ocrController.vehicle_license(token, base64Image);
                case '增值税发票':
                    return ocrController.vat_invoice(token, base64Image);
                case '通行费发票':
                    return ocrController.vat_invoice(token, base64Image);
                case '营业执照':
                    return ocrController.business_license(token, base64Image);
                case '定额发票':
                    return ocrController.quota_invoice(token, base64Image);
                case '火车票':
                    return ocrController.train_ticket(token, base64Image);
                case '机票行程单':
                    return ocrController.air_ticket(token, base64Image);
                case '汽车票':
                    return ocrController.bus_ticket(token, base64Image);
                case '车牌':
                    return ocrController.license_plate(token, base64Image);
                case '智能财务票据':
                    return ocrController.multiple_invoice(token, base64Image);
                default:
                    console.error("不支持的类型:" + type)
            }
        },
        fillData(element, columns, operations, source, data,rawData) {

            let type = element.attrs.type;
            let parent = element.attrs.parent;
            let ocrTypeColumn = _ocrTypeColumns[type];


            operations.filter(filter => filter.to).forEach(operation => {


                let from = operation.from.split("|").find(find => source[find] !== undefined);
                let value = source[from];

                if (parent!=='subForm' && columns.find(find => find.id === operation.to)?.parent) {

                    let subFormId = columns.find(find => find.id === operation.to).parent;
                    let subForm = columns.find(find => find.id === subFormId);


                    //发票明细列表
                    if (Array.isArray(value) && value.length>1) {
                        value.forEach((item, index) => {
                            if (!rawData[subForm.id]) {
                                rawData[subForm.id] = []
                            }

                            if (!rawData[subForm.id][index]) {
                                rawData[subForm.id].push({});
                            }

                            this.fillTarget(item.word, ocrTypeColumn, operation, rawData[subForm.id][index]);
                        })
                    } else {
                        this.fillTarget(value, ocrTypeColumn, operation, rawData[subForm.id][rawData[subForm.id].length - 1]);
                    }

                } else {
                    this.fillTarget(value, ocrTypeColumn, operation, data);
                }

            })
        },
        fillTarget(value, ocrTypeColumn, operation, target) {
            let process = ocrTypeColumn.find(find => find.name === operation.name)?.process || null;
            value = process ? process(value) : value;

            if (value!==undefined && operation.to.startsWith('number')) {
                value =  parseFloat(value);
            }


            if (Array.isArray(target[operation.to])) {
                target[operation.to].push(value);
            } else {
                target[operation.to] = value;
            }
        },
        uploadTemplate(form_id, template_id, template) {
            return post(`/api/ocr/uploadTemplate`, {form_id, template_id, template});
        },
        previewTemplate(template_id) {
            return ocrController.previewTemplate({template_id});
        },
        deleteTemplate(template_id) {
            return ocrController.deleteTemplate({template_id});
        },
        preReadExcel(form_id, template, excel, params) {
            return ocrController.preReadExcel({form_id, template, excel, ...params});
        },
        downloadBase64AsExcel(excel) {

            // 解码Base64字符串为Blob
            const byteCharacters = atob(excel);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});

            // 创建下载链接
            const currentDate = new Date().toISOString().replace(/:/g, '-').slice(0, 10); // 获取当前日期并格式化为YYYY-MM-DD
            const fileName = `${currentDate}.xlsx`;

            // 创建一个临时的a标签用于下载
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;

            // 触发点击
            document.body.appendChild(link);
            link.click();

            // 清理
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
        },
        async ocrSubmit(form_id, template, main, list) {
            let mainData = {};
            main.forEach(item => mainData[item.id] = item.value)
            let rows = []

            switch (template.mode) {
                case 'main_more':
                    for (let item of list) {
                        rows.push(_.merge(item, mainData));
                    }

                    return ocrController.post(form_id, {rows: JSON.stringify(rows)})
            }

            return new Promise((resolve, reject) => {
                resolve({
                    code: 500,
                    message: '暂不支持mode ' + template.mode,
                });
            })
        }
    },
})