<template>

  <el-container>
    <el-header height="30px">
      <span>
        <b style="font-size: 16px;font-weight: bold">公式编辑</b>
      </span>
      <span style="float: right">
      </span>
    </el-header>
    <el-main>
      <slot></slot>
      <!--      code 编辑区-->
      <el-card class="box-card editor">
        <template #header>
          <div class="clearfix">
            <el-link type="primary" @click="insertValue('+')">+</el-link>
            <el-link type="primary" @click="insertValue('-')">-</el-link>
            <el-link type="primary" @click="insertValue('*')">×</el-link>
            <el-link type="primary" @click="insertValue('/')">÷</el-link>
            <el-link type="primary" @click="insertValue('%')">%</el-link>
            <el-link type="primary" @click="insertValue(',')">,</el-link>
          </div>
        </template>
        <groovy-editor ref="groovyEditor" :value="value" :functions="functions" :columns="columns"></groovy-editor>
      </el-card>


    </el-main>

    <el-footer height="260px">
      <el-row :gutter="10">
        <el-col :span="7">
          <!--          表单字段-->
          <el-card class="box-card">
            <template #header>
              <div class="clearfix">
                <div class="clearfix">
                  <div>字段</div>
                  <el-input style="margin: 4px 0" size="small" placeholder="搜索字段" v-model="keywordColumn"
                            type="text"
                            prefix-icon="Search">
                  </el-input>
                </div>
              </div>
            </template>


            <el-tree ref="treeColumn" :data="columns" :props="{children: 'children', label: 'label'}"
                     :filter-node-method="filterNode" @node-click="handleColumnNodeClick">
              <template #default="{data}">
                 <span class="custom-tree-node">
                  <span> {{ data.label }}</span>
                   <span> {{ data.nodeName }}</span>
               </span>
              </template>

            </el-tree>
          </el-card>

        </el-col>
        <el-col :span="7">

          <!--          函数列表-->
          <el-card class="box-card">
            <template #header>
              <div class="clearfix">
                <div>函数列表</div>
                <el-input size="small" v-model="keywordFunction" style="margin: 4px 0" placeholder="搜索函数"
                          prefix-icon="Search"></el-input>
              </div>
            </template>


            <el-tree ref="treeFunction" :data="functions" :props="{children: 'list', label: 'name'}"
                     :filter-node-method="filterNode" :show-checkbox="false" @node-click="handleFunctionNodeClick">
              <template #default="{data}">
              <span class="custom-tree-node">
                <span> {{ data.name }}</span>
                 <span v-if="data.type">
                  <el-tag size="small" :type="getType(data.type)" effect="plain" round>{{ data.type }}</el-tag>
                </span>
              </span>
              </template>

            </el-tree>

          </el-card>

        </el-col>
        <el-col :span="10">

          <!--          函数注释-->
          <el-card class="box-card help">
            <template #header>
              <div class="clearfix" style="height: 20px">
              <span>
                <b v-if="selectFunction">{{ selectFunction.name }}</b>
                  <el-skeleton animated :rows="0" style="width: 80%" v-else/>
              </span>
              </div>
            </template>
            <div v-if="selectFunction" style="padding: 20px;line-height: 20px">
              <b style="color: rgba(199, 21, 133, 1)">{{ selectFunction.name }}</b>
              {{ selectFunction.instructions }}<br><br>
              <b>用法：</b> <b style="color: rgba(199, 21, 133, 1)">{{ selectFunction.name }}</b>
              {{ selectFunction.usage }} <br>
              <b>示例：</b> <b style="color: rgba(199, 21, 133, 1)">{{ selectFunction.name }}</b>
              {{ selectFunction.sample }}
            </div>
            <el-skeleton animated :rows="4" style="padding: 20px;width: 80%" v-else/>
          </el-card>

        </el-col>

      </el-row>
    </el-footer>

  </el-container>

</template>

<script>

import groovyEditor from "@/components/etl/node/groovyEditor";

export default {
  props: {
    value: {
      type: String,
      default: () => ''
    },
    columns: {
      type: Array,
      default: () => []
    },
    functions: {
      type: Array
    }
  },
  components: {
    groovyEditor
  },
  watch: {
    keywordFunction(val) {
      this.$refs.treeFunction.filter(val);
    },
    keywordColumn(val) {
      this.$refs.treeColumn.filter(val);
    },

  },
  data() {
    return {
      script: '',
      row: null,
      keywordColumn: '',
      keywordFunction: '',
      selectFunction: null,
    }
  },
  methods: {
    init(code) {
      code = code ? code : ''
      this.$refs.groovyEditor.setValue(code)
    },
    filterNode(value, data) {

      if (!value) return true;

      return (data.name && data.name.toUpperCase().indexOf(value.toUpperCase()) !== -1) || (data.label && data.label.toUpperCase().indexOf(value.toUpperCase()) !== -1);
    },
    handleColumnNodeClick(data) {
      this.insertValue(data.label)
    },
    handleFunctionNodeClick(data) {
      if (data.type) {
        if (this.selectFunction === data) {
          this.insertValue(data.name + "()", -1)
        } else {
          this.selectFunction = data
        }
      }

    },
    //插入信息
    insertValue(content, offset = 0) {
      this.$refs.groovyEditor.insert(content, offset);
    },
    getText() {
      return this.$refs.groovyEditor.getValue();
    },
    getType(type) {

      switch (type) {
        case "数字":
          return 'warning';
        case "文本":
          return 'primary';
        case "布尔":
          return 'success';
        case "泛型":
          return 'danger';
      }
    }
  }

}
</script>

<style scoped lang="scss">


.el-main {
  padding: 0;
}

.el-header {
  padding: 0;
}

.el-footer {
  margin-top: 20px;
  padding: 0;
}

.el-card {
  color: rgb(40, 38, 38);
  border-radius: 12px;
  box-shadow: none;
  height: 250px;
}

:deep( .el-card__body,.el-tree-node__label ) {
  font-size: 12px;
}

p {
  margin-top: 10px;
  margin-bottom: 0;
}

:deep(.el-input-group__prepend) {
  background-color: white;
  border: none;
  padding: 0;
}

:deep(.el-input__inner) {
  border: none;
}

:deep( .el-card__body, .el-main ) {
  padding: 1px 1px 1px 1px;
}

:deep(.help) {
  .el-card__header {
    padding: 10px 14px
  }
}


:deep(.editor ) {
  .el-card__header {
    padding: 6px 14px
  }


}

:deep( .el-card__header) {
  padding: 6px 14px 4px 14px
}


.el-tree {
  height: 176px;
  overflow: auto;
}

:deep(.el-tree-node__content > .el-tree-node__expand-icon ) {
  margin-right: 4px;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
}

:deep(.CodeMirror pre.CodeMirror-line,  .CodeMirror pre.CodeMirror-line-like) {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.el-link {
  margin-left: 20px;

  :deep(.el-link--inner) {
    font-size: 12px;
  }
}


</style>
