<template>


  <div style="padding: 10px;background-color: white">
  <el-table :data="data" border :height="element.h-60" :header-cell-style="{color:'#181A1D',backgroundColor:'#F1F2F3'}">
    <el-table-column v-for="(key,index) in Object.keys(data[0])" :key="index" :label="key" :prop="key"></el-table-column>
  </el-table>

  <el-pagination sty layout="prev, pager, next" :total="data.length" style="margin: 6px 0 0 0 ;" />
  </div>
</template>


<script>
export default {
  props: {
    element: {
      城市: Object,
      default: () => {
        return {}
      }
    }
  },
  data(){
    return {
      data: [
        {
          省份: '浙江',
          城市: '杭州',
          区: '西湖区',
          销售额: 1000,
          利润: 500
        },
        {
          省份: '浙江',
          城市: '杭州',
          区: '西湖区',
          销售额: 1000,
          利润: 500
        },
        {
          省份: '浙江',
          城市: '杭州',
          区: '西湖区',
          销售额: 1000,
          利润: 500
        },
        {
          省份: '浙江',
          城市: '杭州',
          区: '西湖区',
          销售额: 1000,
          利润: 500
        },
        {
          省份: '浙江',
          城市: '杭州',
          区: '西湖区',
          销售额: 1000,
          利润: 500
        },
      ]
    }
  }


}

</script>

<style scoped lang="scss">

</style>