import {defineStore} from 'pinia'
import {get, post, post2, post3, put, put2, $delete} from '@/plugins/axios'
import formController from "@/controller/formController";

export const useHttpStore = defineStore('http', {
    state: () => {
        return {
            url: '',
            bodyType: '',
            headers: [],
            params: [],
            bodies: [],
        }
    },
    getters: {},
    actions: {
        async send(context) {
            return await formController.remoteRequest(context);
        },
        // async send(context) {
        //     let method = context.method;
        //
        //     this.url = context.url;
        //     this.bodyType = context.bodyType;
        //     this.headers = {};
        //
        //     context.headers.forEach(item=>{
        //         this.headers[item.key] = item.value;
        //     })
        //
        //     this.params = context.params;
        //     this.bodies = context.bodies;
        //
        //     switch (method) {
        //         case 'GET':
        //             return await this.get();
        //         case 'POST':
        //             return this.post();
        //         default:
        //             throw new Error(`不支持的请求类型:${method}`)
        //     }
        // },
        // async get() {
        //     return await get(this.url, this.params, this.headers);
        // },
        // async post() {
        //     let urlWithParams = this.url;
        //     if (this.params && Object.keys(this.params).length > 0) {
        //         const queryString = qs.stringify(this.params);
        //         if (urlWithParams.includes('?')) {
        //             urlWithParams += `&${queryString}`;
        //         } else {
        //             urlWithParams += `?${queryString}`;
        //         }
        //     }
        //
        //     if (this.bodyType === 'application/json') {
        //         return await post2(urlWithParams, this.bodies, this.headers);
        //     } else if (this.bodyType === 'form-data') {
        //         return await post3(urlWithParams, this.bodies, this.headers);
        //     } else {
        //         return await post(urlWithParams, this.bodies, this.headers);
        //     }
        // }

    },
    persist: {
        enabled: false
    }
})