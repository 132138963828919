<template>
  <div style="height: 300PX">
    <div ref="code-editor" style="height: 100%; width: 100%"></div>
  </div>
</template>

<script>
import * as monaco from "monaco-editor";
import {toRaw} from 'vue'

const keywords = [
  'abstract',
  'continue',
  'for',
  'new',
  'switch',
  'default',
  'package',
  'synchronized',
  'boolean',
  'do',
  'if',
  'private',
  'this',
  'break',
  'double',
  'implements',
  'protected',
  'throw',
  'byte',
  'else',
  'import',
  'public',
  'throws',
  'case',
  'enum',
  'instanceof',
  'return',
  'catch',
  'extends',
  'int',
  'short',
  'try',
  'char',
  'final',
  'static',
  'void',
  'class',
  'finally',
  'long',
  'var',
  'const',
  'float',
  'while',
  'true',
  'false',
  'record',
];
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    functions: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      monacoEditor: null,
      theme: "vs-dark", // 默认是 "vs"
    };
  },
  mounted() {
    this.$nextTick(() => {
      console.log('Groovy Editor - mounted');
      console.log('monaco:', monaco);
      console.log('code-editor ref:', this.$refs["code-editor"]);

      if (!this.$refs["code-editor"]) {
        console.error('code-editor ref is not available');
        return;
      }

      this.initAutoCompletion();
      // 初始化编辑器
      this.monacoEditor = monaco.editor.create(this.$refs["code-editor"], {
        value: this.value, // 初始文字
        language: "java", // 语言
        readOnly: false, // 是否只读
        automaticLayout: true, // 自动布局
        theme: this.theme, // vs | hc-black | vs-dark
        minimap: {
          enabled: false, // 关闭小地图
        },
        tabSize: 2, // tab缩进长度
        fontSize: 14, // 文字大小
      });
    });
  },
  methods: {
    getValue() {
      if (this.monacoEditor) {
        return toRaw(this.monacoEditor).getValue();
      }

    },
    setValue(val) {
      if (this.monacoEditor) {
        toRaw(this.monacoEditor).setValue(val);
      }
    },
    insert(val, offset = 0) {
      if (!val) return;

      let monacoEditor = toRaw(this.monacoEditor);

      const position = monacoEditor.getPosition()
      monacoEditor.trigger('keyboard', 'type', {text: val});
      monacoEditor.setPosition({lineNumber: position.lineNumber, column: position.column + val.length + offset})
      monacoEditor.focus()
    },
    /**
     * @description: 初始化自动补全
     */
    initAutoCompletion() {
      monaco.languages.registerCompletionItemProvider("java", {
        // 触发提示的字符
        triggerCharacters: [".", " ", ...keywords],
        provideCompletionItems: (model, position) => {
          let suggestions = [];
          // 行号，列号
          const {lineNumber, column} = position;
          // 光标之前的所有字符，即从这一行的 0 到当前的字符
          const textBeforePointer = model.getValueInRange({
            startLineNumber: lineNumber,
            startColumn: 0,
            endLineNumber: lineNumber,
            endColumn: column,
          });

          // trim() 取消两边空格，保证拆分出来前后都不是空值
          // \s是指空白，包括空格、换行、tab缩进等所有的空白
          const words = textBeforePointer.trim().split(/\s+/);

          // 最后的一个有效词
          const lastWord = words[words.length - 1];
          console.log(lastWord)

          if (lastWord.endsWith(".")) { // 如果这个词以 . 结尾，那么认为是希望补全表的字段
            suggestions = [{
              label: 'length',
              kind: monaco.languages.CompletionItemKind.Field,
              insertText: 'length'
            }];

          } else if (lastWord === ".") {
            // 如果这个词本身就是一个 . 即点前面是空的，那么什么都不用补全了
            // 按理说这应该是个语法错误
            suggestions = [];
          } else {
            // 其他时候都补全表名，以及关键字
            suggestions = [...this.getFunctionSuggest(), ...this.getKeywordsSuggest(), ...this.getFieldsSuggest()];
          }

          return {
            suggestions,
          };
        },
      });

    },

    /**
     * @description: 获取关键字的补全列表
     * @tips: CompletionItemKind 的所有枚举可以在monaco.d.ts 文件中找到，有二十多个，取需即可
     */
    getKeywordsSuggest() {
      return keywords.map((key) => ({
        label: key,// 显示的名称
        kind: monaco.languages.CompletionItemKind.Keyword,
        insertText: key,// 真实补全的值
      }));
    },

    /**
     * @description: 获取表名的补全列表
     */
    getFunctionSuggest() {

      let result = []

      this.functions.forEach(type => {
        type.list.forEach(fun => {
          result.push({
            label: `${fun.name} ${fun.instructions.split(',')[0]}`, // 显示的名称
            kind: monaco.languages.CompletionItemKind.Function,
            insertText: `${fun.name}()`, // 真实补全的值
          })
        })
      })

      return result;
    },

    /**
     * @description: 根据表名获取字段补全列表
     * @param {*} tableName
     */
    getFieldsSuggest() {
      if (!this.columns) {
        return [];
      }

      return this.columns.map(column => {
        return {
          label: column.label,
          kind: monaco.languages.CompletionItemKind.Field,
          insertText: column.label
        }
      });
    },
  },
  unmounted() {
    // 销毁之前把monaco的实例也销毁了，不然会多次注册
    if (this.monacoEditor) {
      let monacoEditor = toRaw(this.monacoEditor);
      monacoEditor.dispose();
    }
  },
};
</script>

<style scoped>

</style>