import {
    get,
    post,
    post2,
    put,
    put2,
    $delete
} from '@/plugins/axios.js'

export default {
    table(token, image) {
        return post(`https://aip.baidubce.com/rest/2.0/ocr/v1/table?access_token=${token}`, {
            cell_contents: false, return_excel: true, image
        });
    },
    /** 身份证  */
    idcard(token, image) {
        return post(`https://aip.baidubce.com/rest/2.0/ocr/v1/idcard?access_token=${token}`, {
            image
        });
    },
    /** 银行卡  */
    bankcard(token, image) {
        return post(`https://aip.baidubce.com/rest/2.0/ocr/v1/bankcard?access_token=${token}`, {
            image
        });
    },
    /** 驾驶证  */
    driving_license(token, image) {
        return post(`https://aip.baidubce.com/rest/2.0/ocr/v1/driving_license?access_token=${token}`, {
            image
        });
    },
    /** 行驶证  */
    vehicle_license(token, image) {
        return post(`https://aip.baidubce.com/rest/2.0/ocr/v1/vehicle_license?access_token=${token}`, {
            image
        });
    },
    /** 增值税发票  */
    vat_invoice(token, image) {
        return post(`https://aip.baidubce.com/rest/2.0/ocr/v1/vat_invoice?access_token=${token}`, {
            image
        });
    },
    /** 定额发票  */
    quota_invoice(token, image) {
        return post(`https://aip.baidubce.com/rest/2.0/ocr/v1/quota_invoice?access_token=${token}`, {
            image
        });
    },
    /** 火车票  */
    train_ticket(token, image) {
        return post(`https://aip.baidubce.com/rest/2.0/ocr/v1/train_ticket?access_token=${token}`, {
            image
        });
    },
    /** 汽车票  */
    bus_ticket(token, image) {
        return post(`https://aip.baidubce.com/rest/2.0/ocr/v1/bus_ticket?access_token=${token}`, {
            image
        });
    },
    /** 飞机票  */
    air_ticket(token, image) {
        return post(`https://aip.baidubce.com/rest/2.0/ocr/v1/air_ticket?access_token=${token}`, {
            image
        });
    },
    /** 营业执照  */
    business_license(token, image) {
        return post(`https://aip.baidubce.com/rest/2.0/ocr/v1/business_license?access_token=${token}`, {
            image
        });
    },
    /** 车牌  */
    license_plate(token, image) {
        return post(`https://aip.baidubce.com/rest/2.0/ocr/v1/license_plate?access_token=${token}`, {
            image
        });
    },
    multiple_invoice(token, image){
        return post(`https://aip.baidubce.com/rest/2.0/ocr/v1/multiple_invoice?access_token=${token}`, {
            image
        });
    },


    getToken() {
        return post(`/api/ocr/getToken`);
    },
    uploadTemplate(params) {
        return post(`/api/ocr/uploadTemplate`, params);
    },
    previewTemplate(params) {
        return post(`/api/ocr/previewTemplate`, params);
    },
    deleteTemplate(params) {
        return $delete(`/api/ocr/deleteTemplate`, params);
    },
    preReadExcel(params) {
        return post2(`/api/ocr/preReadExcel`, params);
    },
    post(formId, params) {
        return post(`/api/ocr/postData/${formId}`, params);
    }


}
