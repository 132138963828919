<template>
  <el-form-item>
    <template #label>
      <span :style="{display: 'flex','align-items': 'center',color:'#344262','font-weight':context.formStyleSetting.PC.title}">
        {{ element.label }}
        <form-element-tooltip :element="element"></form-element-tooltip>
      </span>
    </template>
    <!-- 日期格式 -->
    <el-date-picker v-if="element.attrs.format!=='HH:mm'"  :readonly="true" :type="getType"
                    :placeholder="element.attrs.formatOptions.filter(filter=>filter.value === element.attrs.format)[0].label"
                    :format="element.attrs.format" :value-format="element.attrs.format" style="width: 100%">
    </el-date-picker>
    <!-- 时间格式 -->
    <el-time-picker v-else :readonly="true"  :placeholder="element.attrs.formatOptions.filter(filter=>filter.value === element.attrs.format)[0].label"
                    :format="element.attrs.format" :value-format="element.attrs.format" style="width: 100%"/>
  </el-form-item>
</template>

<script>

import FormElementTooltip from "@/components/common/formElementTooltip.vue";

export default {
  components: {FormElementTooltip},
  props: {
    context: {
      type: Object,
      default: () => {
      }
    },
    element: {
      type: Object,
      default: () => {
      }
    },
    parent: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getType() {
      switch (this.element.attrs.format) {
        case 'YYYY-MM-DD':
          return 'date';
        case 'YYYY-MM':
          return 'month';
        case 'YYYY':
          return 'year';
        case 'YYYY-MM-DD HH:mm':
          return 'datetime';
        case 'YYYY-MM-DD HH:mm:ss':
          return 'datetime';
      }

      return '';
    }
  },
  created() {

  },
  data() {
    return {
      data: this.element,
    }
  }
}

</script>

<style scoped lang="scss">
</style>