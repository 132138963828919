<template>

  <el-drawer v-model="visible" direction="rtl" :size="fullScreen?'100%': '65%'" :show-close="false"
             :append-to-body="true"
             :modal="false" :wrapperClosable="false" :destroy-on-close="true"
             style="background-color: #F5F7FA" @close="handleClose">

    <template #header="{  titleId, titleClass ,close}">
      <span :id="titleId" :class="titleClass"
            style="font-size: 16px;font-weight: bold;color: rgb(48,66,101)">{{ form?.name || '' }}</span>
      <span>
        <el-button v-prevent-re-click v-if="form && formPermission.isEdit() && editAuthentication?.isShowEditButton()"
                   icon="Edit" link type="info"
                   style="color: #8A94A5"
                   @click="$refs.appFormAdd.call(form.id,deepClone(data))">编辑</el-button>


         <el-popover ref="popover" trigger="click" width="180px" v-if="form">
            <ul>
                <li v-for="(item,index) in form.setting.form.printers"
                    @click="go(`/print-template/${form.id}/${data.id}/${index}`,null,true);" :key="index">{{
                    item.name
                  }}</li>
              <li @click="go(`/print/${form.id}/${data.id}`,null,true);">默认打印模版</li>
            </ul>
            <template #reference>
              <el-button v-if="form && formPermission.isPrint()" v-prevent-re-click icon="Printer" link type="info"
                         style="color: #8A94A5">打印</el-button>
            </template>
          </el-popover>

         <el-button v-prevent-re-click v-if="form && formPermission.isDelete()" icon="Delete" link type="info"
                    style="color: #8A94A5"
                    @click="formStore.deleteRow(form.id,data.id).then(res=>{
                      visible=false;
                      $emit('refresh');
                    })">删除</el-button>

        <el-button v-prevent-re-click :icon="fullScreen?'Minus':'FullScreen'" type="info" link
                   style="margin-left: 20px;font-size: 16px;color: #8A94A5"
                   @click="fullScreen=!fullScreen"></el-button>

        <el-button v-prevent-re-click icon="Close" type="info" link style="font-size: 20px;color: #8A94A5"
                   @click="close()"></el-button>
      </span>
    </template>
    <el-scrollbar height="calc(100vh - 100px)"
                  style="background-color: white;padding: 0 20px 20px 20px; border-radius: 8px;"
                  v-loading="loading"
                  element-loading-text="努力加载中..."
                  class="back-top">
      <el-form v-if="form && data" ref="form" :model="data" :label-position="form.setting.style.PC.layout"
               label-width="auto"
               style="min-height: 50vh;background-color: white;padding: 24px 20px 0 20px;border-radius: 10px">
        <template v-for="(element,index) in form.elements" :key="index">
          <keep-alive>
            <component v-if="viewAuthentication.isRead(element)" v-show="viewAuthentication.isHide(element,data)"
                       :is="`design${element.tag.charAt(0).toUpperCase() + element.tag.slice(1)}Preview`"
                       :element="element"
                       :context="{ form:form, data:data, parent:form.elements,authentication:viewAuthentication}">
            </component>
          </keep-alive>
        </template>
      </el-form>


      <div class="container"
           v-if="form_id && data_id && (form&&formPermission.isComment() || form&&formPermission.isChange())">
        <b style="font-size: 16px;">其它</b>
        <el-divider></el-divider>
        <el-radio-group v-model="other" style="margin-bottom: 20px;">
          <el-radio-button v-if="form&&formPermission.isComment()" value="评论">评论</el-radio-button>
          <el-radio-button v-if="form&&formPermission.isChange()" value="变更记录">变更记录</el-radio-button>
        </el-radio-group>

        <comment-show v-if="other==='评论'" :form_id="form_id" :data_id="data_id"></comment-show>
        <form-time-line v-if="other==='变更记录'" :form_id="form_id" :data_id="data_id" style="margin-top: 20px"></form-time-line>
      </div>
      <el-backtop :bottom="50" :right="60" target=".back-top .el-scrollbar__wrap"/>
    </el-scrollbar>


  </el-drawer>

  <app-form-edit ref="appFormAdd" @callback="handleCallback"></app-form-edit>

</template>

<script>
import {mapStores} from "pinia";
import {useFormStore} from "@/stores/form";
import {useMenuStore} from "@/stores/menu";
import {useFormAuthenticationStore} from "@/stores/form-authentication";
import commentShow from "@/components/design-form-list/commentShow.vue";
import AppFormEdit from "@/components/app-form/app-form-edit.vue";
import FormTimeLine from "@/components/common/formTimeLine.vue";
import {useFormPermissionStore} from "@/stores/formPermission";

export default {
  emits: ['refresh'],
  components: {FormTimeLine, AppFormEdit, commentShow},
  data() {
    return {
      visible: false,
      form: null,
      data: null,
      columns: [],
      fields: [],
      other: '评论',
      fullScreen: false,
      loading: false,
      viewAuthentication: null,
      editAuthentication: null,
      form_id: '',
      data_id: '',
      formPermission: null,
    }
  },
  computed: {
    ...mapStores(useFormStore, useMenuStore, useFormAuthenticationStore, useFormPermissionStore)
  },
  methods: {
    call(form_id, data_id) {
      this.form = null;
      this.data = null;
      this.form_id = form_id;
      this.data_id = data_id;
      this.query();

      this.$nextTick(() => this.visible = true)
    },
    query() {
      this.loading = true
      this.menuStore.findById(this.form_id).then(res => {
        this.form = res.data;
        this.formPermission = this.formPermissionStore.init(this.form)
        if (this.formPermission.isChange() && !this.formPermission.isComment()) {
          this.other = '变更记录'
        }
        this.columns = this.formStore.columns(this.form);
        this.fields = !this.form.setting.list.fields || this.form.setting.list.fields.length === 0 ? this.columns.map(mapper => mapper.id) : this.form.setting.list.fields;
        this.filters = !this.form.setting.list.filters ? [] : this.form.setting.list.filters;
        this.viewAuthentication = this.formAuthenticationStore.view(this.form);
        this.editAuthentication = this.formAuthenticationStore.edit(this.form);
        this.loading = false
        this.findDataById();
      })
    },
    findDataById() {
      this.loading = true;
      this.formStore.findById(this.form_id, this.data_id).then(res => {
        this.data = res.data;
        this.loading = false;
      })
    },
    handleClose() {
      this.form = null;
      this.data = null;
      this.columns = [];
      this.fields = [];
      this.viewAuthentication = null;
      this.editAuthentication = null;
      this.form_id = '';
      this.data_id = '';
      this.$emit('refresh');
    },
    handleCallback() {
      this.data = null;
      this.$nextTick(() => {
        this.findDataById();
      })

    },
    print(form_id, data_id) {
      this.go(`/print/${form_id}/${data_id}`, null, true)
    }
  }

}

</script>

<style lang="scss" scoped>

.container {
  background-color: white;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-top: 40px;
  min-height: 200px;
  border-radius: 8px;
}

.el-popover {
  ul {
    padding: 0;
    margin: 4px 0;
  }

  li {
    list-style-type: none;
    text-align: center;
    line-height: 40px;
    color: #595959;
    cursor: pointer;
    font-size: 13px;
  }

  li:hover {
    background-color: #F5F8FC;
  }
}

</style>